<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>用户管理</a-breadcrumb-item>
    <a-breadcrumb-item>消费记录</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 日期： -->
    <span class="tool-title">日期：</span>
    <a-range-picker
      v-model:value="dateRange"
      :format="dateFormat"
    />
    <!-- UID： -->
    <span class="tool-title">UID：</span>
    <a-input v-model:value="uid" style="width: 160px;" placeholder="请输入" />
    <!-- 平台： -->
    <span class="tool-title">平台：</span>
    <a-select v-model:value="platform" style="width: 120px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option v-for="item in Platforms" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
    </a-select>
    <!-- 短剧 -->
    <span class="tool-title">短剧：</span>
    <a-select
      style="width: 200px;"
      show-search
      v-model:value="dramaID"
      placeholder="请选择"
      :filter-option="filterOption"
    >
      <a-select-option
        v-for="item in dramas"
        :key="`${item.project_drama_id}-${item.remark_name}`"
        :value="item.project_drama_id"
      >
        {{ item.remark_name }}
      </a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 昵称 -->
      <template v-if="column.key === 'username'">
        {{ record.user.username }}
      </template>
      <!-- 平台 -->
      <template v-if="column.key === 'platform_id'">
        {{ (Platforms.find(item => item.value === record[column.key]) || {}).text || '-' }}
      </template>
      <!-- 半折卡 -->
      <template v-if="column.key === 'is_half_past_card'">
        {{ record.is_half_past_card ? '是' : '否' }}
      </template>
    </template>
  </a-table>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'
import { Platforms } from '@/utils/constantList'
import { consumeList, projectDramaAll } from '@/api/operate'

// 加载
let isLoading = ref(false)
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 短剧列表
let dramas = ref([])
// 短剧ID
let dramaID = ref(undefined)
// UID
let uid = ref(undefined)
// 平台
let platform = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '消费时间',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  {
    title: '昵称',
    dataIndex: 'username',
    key: 'username'
  },
  {
    title: '平台',
    dataIndex: 'platform_id',
    key: 'platform_id'
  },
  {
    title: '消费嗨豆',
    dataIndex: 'real_coin',
    key: 'real_coin'
  },
  {
    title: '半折卡',
    dataIndex: 'is_half_past_card',
    key: 'is_half_past_card'
  },
  {
    title: '消费内容',
    dataIndex: 'consume_content',
    key: 'consume_content'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取消费列表
  getConsumeList()
  // 获取短剧列表
  getProjectDramaAll()
})

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getConsumeList()
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getConsumeList()
}

// 重置
function handleReset () {
  // 筛选项重置
  dateRange.value = null
  uid.value = undefined
  platform.value = undefined
  dramaID.value = undefined
  // 分页重置
  pagination.current = 1
  getConsumeList()
}

// 短剧列表
function getProjectDramaAll () {
  projectDramaAll().then(res => {
    const { code, data } = res
    if (code === 0) {
      dramas.value = data
    }
  })
}

// 获取消费列表
function getConsumeList () {
  isLoading.value = true
  const params = {
    start_at: dateRange.value ? `${dateRange.value[0].format(dateFormat)}` : undefined,
    end_at: dateRange.value ? `${dateRange.value[1].format(dateFormat)}` : undefined,
    uid: uid.value,
    project_drama_id: dramaID.value,
    platform_id: platform.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  consumeList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>